import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryDefaultSkin/MatrixGalleryDefaultSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryDefaultSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryDefaultSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryTextSlideUpSkin/MatrixGalleryTextSlideUpSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextSlideUpSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryTextSlideUpSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryTextOnCenterSkin/MatrixGalleryTextOnCenterSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextOnCenterSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryTextOnCenterSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryCircleSkin/MatrixGalleryCircleSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryCircleSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryCircleSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryLiftedShadow/MatrixGalleryLiftedShadow.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryLiftedShadow" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryLiftedShadow',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryTransparentSkin/MatrixGalleryTransparentSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTransparentSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryTransparentSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/PolaroidCustomHeightSkin/PolaroidCustomHeightSkin.skin' /* webpackChunkName: "MatrixGallery_PolaroidCustomHeightSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'PolaroidCustomHeightSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryTextBelowSkin/MatrixGalleryTextBelowSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextBelowSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryTextBelowSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/BlogMatrixGallery/BlogMatrixGallery.skin' /* webpackChunkName: "MatrixGallery_BlogMatrixGallery" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'BlogMatrixGallery',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryPolaroidSkin/MatrixGalleryPolaroidSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryPolaroidSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryPolaroidSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryScotchTapeSkin/MatrixGalleryScotchTapeSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryScotchTapeSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryScotchTapeSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryIronSkin/MatrixGalleryIronSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryIronSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryIronSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGallerySeparateTextBoxSkin/MatrixGallerySeparateTextBoxSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGallerySeparateTextBoxSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGallerySeparateTextBoxSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGallerySloopy/MatrixGallerySloopy.skin' /* webpackChunkName: "MatrixGallery_MatrixGallerySloopy" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGallerySloopy',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/TextBottomCustomHeightSkin/TextBottomCustomHeightSkin.skin' /* webpackChunkName: "MatrixGallery_TextBottomCustomHeightSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'TextBottomCustomHeightSkin',
    );
  },
};

export default entry;
