import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'FormContainer',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/FormContainerSkin/FormContainerSkin.skin' /* webpackChunkName: "FormContainer_FormContainerSkin" */
          ),
          import(
            './viewer/FormContainer.controller' /* webpackChunkName: "FormContainer_FormContainerSkin" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'FormContainerSkin',
    );

    hostAPI.registerComponent(
      'FormContainer',
      () => {
        return import(
          './viewer/skinComps/ResponsiveSkin/ResponsiveSkin.skin' /* webpackChunkName: "FormContainer_ResponsiveSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ResponsiveSkin',
    );

    /** Backward compatiability - can be removed once uiType in TB is merged */
    hostAPI.registerComponent('FormContainer', () => {
      return import(
        './viewer/FormContainer' /* webpackChunkName: "FormContainerSkin" */
      ).then(componentModule => {
        return {
          component: componentModule.default,
        };
      });
    });
  },
};

export default entry;
