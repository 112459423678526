import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryDefaultSkin/SliderGalleryDefaultSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryDefaultSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryDefaultSkin',
    );

    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryCircleSkin/SliderGalleryCircleSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryCircleSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryCircleSkin',
    );

    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryScotchTapeSkin/SliderGalleryScotchTapeSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryScotchTapeSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryScotchTapeSkin',
    );

    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryNoArrow/SliderGalleryNoArrow.skin' /* webpackChunkName: "SliderGallery_SliderGalleryNoArrow" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryNoArrow',
    );

    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryIronSkin/SliderGalleryIronSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryIronSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryIronSkin',
    );

    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryMinimal/SliderGalleryMinimal.skin' /* webpackChunkName: "SliderGallery_SliderGalleryMinimal" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryMinimal',
    );
  },
};

export default entry;
