import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent('DatePicker', () =>
      Promise.all([
        import('./viewer/DatePicker' /* webpackChunkName: "DatePicker" */),
        import(
          './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker" */
        ),
      ]).then(([componentModule, controllerModule]) => ({
        component: componentModule.default,
        controller: controllerModule.default,
      })),
    );
    hostAPI.registerComponent(
      'DatePicker',
      () =>
        Promise.all([
          import(
            './viewer/skinComps/DatePickerDefaultSkin/DatePickerDefaultSkin.skin' /* webpackChunkName: "DatePicker_DatePickerDefaultSkin" */
          ),
          import(
            './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker_DatePickerDefaultSkin" */
          ),
        ]).then(([componentModule, controllerModule]) => ({
          component: componentModule.default,
          controller: controllerModule.default,
        })),
      'DatePickerDefaultSkin',
    );
    hostAPI.registerComponent(
      'DatePicker',
      () =>
        Promise.all([
          import(
            './viewer/skinComps/DatePickerTextBetweenNavSkin/DatePickerTextBetweenNavSkin.skin' /* webpackChunkName: "DatePicker_DatePickerTextBetweenNavSkin" */
          ),
          import(
            './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker_DatePickerTextBetweenNavSkin" */
          ),
        ]).then(([componentModule, controllerModule]) => ({
          component: componentModule.default,
          controller: controllerModule.default,
        })),
      'DatePickerTextBetweenNavSkin',
    );
    hostAPI.registerComponent(
      'DatePicker',
      () =>
        Promise.all([
          import(
            './viewer/skinComps/DatePickerTextYearNavSkin/DatePickerTextYearNavSkin.skin' /* webpackChunkName: "DatePicker_DatePickerTextYearNavSkin" */
          ),
          import(
            './viewer/DatePicker.controller' /* webpackChunkName: "DatePicker_DatePickerTextYearNavSkin" */
          ),
        ]).then(([componentModule, controllerModule]) => ({
          component: componentModule.default,
          controller: controllerModule.default,
        })),
      'DatePickerTextYearNavSkin',
    );
  },
};

export default entry;
