import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/ArrowLine/ArrowLine.skin' /* webpackChunkName: "FiveGridLine_ArrowLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ArrowLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/ArrowRightLine/ArrowRightLine.skin' /* webpackChunkName: "FiveGridLine_ArrowRightLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ArrowRightLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/FiveGridLineSkin/FiveGridLineSkin.skin' /* webpackChunkName: "FiveGridLine_FiveGridLineSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'FiveGridLineSkin',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/DashedLine/DashedLine.skin' /* webpackChunkName: "FiveGridLine_DashedLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'DashedLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/DottedLine/DottedLine.skin' /* webpackChunkName: "FiveGridLine_DottedLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'DottedLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/DoubleLine/DoubleLine.skin' /* webpackChunkName: "FiveGridLine_DoubleLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'DoubleLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/DoubleLine2/DoubleLine2.skin' /* webpackChunkName: "FiveGridLine_DoubleLine2" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'DoubleLine2',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/DoubleLine3/DoubleLine3.skin' /* webpackChunkName: "FiveGridLine_DoubleLine3" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'DoubleLine3',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/FadeLine/FadeLine.skin' /* webpackChunkName: "FiveGridLine_FadeLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'FadeLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/FadeNotchBottomLine/FadeNotchBottomLine.skin' /* webpackChunkName: "FiveGridLine_FadeNotchBottomLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'FadeNotchBottomLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/FadeNotchTopLine/FadeNotchTopLine.skin' /* webpackChunkName: "FiveGridLine_FadeNotchTopLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'FadeNotchTopLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/FiveGridLine/FiveGridLine.skin' /* webpackChunkName: "FiveGridLine_FiveGridLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'FiveGridLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/IronLine/IronLine.skin' /* webpackChunkName: "FiveGridLine_IronLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'IronLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/NotchDashedLine/NotchDashedLine.skin' /* webpackChunkName: "FiveGridLine_NotchDashedLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'NotchDashedLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/NotchLine/NotchLine.skin' /* webpackChunkName: "FiveGridLine_NotchLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'NotchLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/ShadowBottomLine/ShadowBottomLine.skin' /* webpackChunkName: "FiveGridLine_ShadowBottomLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ShadowBottomLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/ShadowTopLine/ShadowTopLine.skin' /* webpackChunkName: "FiveGridLine_ShadowTopLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ShadowTopLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/SloppyLine/SloppyLine.skin' /* webpackChunkName: "FiveGridLine_SloppyLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SloppyLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/SolidLine/SolidLine.skin' /* webpackChunkName: "FiveGridLine_SolidLine" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SolidLine',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/ZigzagLineFlipSkin/ZigzagLineFlipSkin.skin' /* webpackChunkName: "FiveGridLine_ZigzagLineFlipSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ZigzagLineFlipSkin',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/ZigzagLineSkin/ZigzagLineSkin.skin' /* webpackChunkName: "FiveGridLine_ZigzagLineSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ZigzagLineSkin',
    );
    hostAPI.registerComponent(
      'FiveGridLine',
      () => {
        return import(
          './viewer/skinComps/SkinNotFound/SkinNotFound.skin' /* webpackChunkName: "FiveGridLine_SkinNotFound" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SkinNotFound',
    );
  },
};

export default entry;
