import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/ThreeDeePage/ThreeDeePageSkin.skin' /* webpackChunkName: "Page_ThreeDeePageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ThreeDeePageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/SloopyPage/SloopyPageSkin.skin' /* webpackChunkName: "Page_SloopyPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SloopyPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/LiftedTop/LiftedTopPageSkin.skin' /* webpackChunkName: "Page_LiftedTopPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'LiftedTopPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/LiftedShadow/LiftedShadowPageSkin.skin' /* webpackChunkName: "Page_LiftedShadowPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'LiftedShadowPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/LiftedBottom/LiftedBottomPageSkin.skin' /* webpackChunkName: "Page_LiftedBottomPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'LiftedBottomPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/BasePage/BasicPageSkin.skin' /* webpackChunkName: "Page_BasicPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'BasicPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/BasePage/BorderPageSkin.skin' /* webpackChunkName: "Page_BorderPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'BorderPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/BasePage/InnerShadowPageSkin.skin' /* webpackChunkName: "Page_InnerShadowPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'InnerShadowPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/BasePage/NoMarginPageSkin.skin' /* webpackChunkName: "Page_NoMarginPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'NoMarginPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/BasePage/ShinyIPageSkin.skin' /* webpackChunkName: "Page_ShinyIPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ShinyIPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/BasePage/TransparentPageSkin.skin' /* webpackChunkName: "Page_TransparentPageSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'TransparentPageSkin',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/Responsive/ResponsivePageWithColorBG.skin' /* webpackChunkName: "Page_ResponsivePageWithColorBG" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'ResponsivePageWithColorBG',
    );

    hostAPI.registerComponent(
      'Page',
      () => {
        return import(
          './viewer/skinComps/VerySimple/VerySimpleSkin.skin' /* webpackChunkName: "Page_VerySimpleSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'VerySimpleSkin',
    );
  },
};

export default entry;
