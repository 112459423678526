import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent('DatePickerCalendar', () =>
      import(
        './viewer/DatePickerCalendar' /* webpackChunkName: "DatePickerCalendar" */
      ).then(componentModule => ({
        component: componentModule.default,
      })),
    );
  },
};

export default entry;
