import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent('RichTextBoxLinkModal', () =>
      import(
        // We want it to use the same chunk name as the main RichTextBox component
        './viewer/RichTextBoxLinkModal' /* webpackChunkName: "RichTextBox" */
      ).then(componentModule => ({
        component: componentModule.default,
      })),
    );
  },
};

export default entry;
