import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    // TODO - remove this default skin after TB uiType PR is merged
    hostAPI.registerComponent('WPhoto', () => {
      return Promise.all([
        import(
          './viewer/skinComps/BasicWPhoto/ClipArtSkin.skin' /* webpackChunkName: "WPhoto" */
        ),
        import('./WPhoto.controller' /* webpackChunkName: "WPhoto" */),
      ]).then(([componentModule, controllerModule]) => {
        return {
          component: componentModule.default,
          controller: controllerModule.default,
        };
      });
    });

    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/ClipArtSkin.skin' /* webpackChunkName: "WPhoto_ClipArtSkin" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_ClipArtSkin" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ClipArtSkin',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/CirclePhoto.skin' /* webpackChunkName: "WPhoto_CirclePhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_CirclePhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'CirclePhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/DefaultPhoto.skin' /* webpackChunkName: "WPhoto_DefaultPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_DefaultPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'DefaultPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/DoubleBorderCirclePhoto.skin' /* webpackChunkName: "WPhoto_DoubleBorderCirclePhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_DoubleBorderCirclePhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'DoubleBorderCirclePhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/DoubleBorderPhoto.skin' /* webpackChunkName: "WPhoto_DoubleBorderPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_DoubleBorderPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'DoubleBorderPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/GlowLinePhoto.skin' /* webpackChunkName: "WPhoto_GlowLinePhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_GlowLinePhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'GlowLinePhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/IronPhoto/IronPhoto.skin' /* webpackChunkName: "WPhoto_IronPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_IronPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'IronPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/LiftedShadowPhoto.skin' /* webpackChunkName: "WPhoto_LiftedShadowPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_LiftedShadowPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'LiftedShadowPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/LiftedTopPhoto.skin' /* webpackChunkName: "WPhoto_LiftedTopPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_LiftedTopPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'LiftedTopPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/MouseOverPhoto.skin' /* webpackChunkName: "WPhoto_MouseOverPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_MouseOverPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'MouseOverPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/NewPolaroidPhoto/NewPolaroidPhoto.skin' /* webpackChunkName: "WPhoto_NewPolaroidPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_NewPolaroidPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'NewPolaroidPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/NoSkinPhoto.skin' /* webpackChunkName: "WPhoto_NoSkinPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_NoSkinPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'NoSkinPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/PaperclipPhoto/PaperclipPhoto.skin' /* webpackChunkName: "WPhoto_PaperclipPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_PaperclipPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'PaperclipPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/PolaroidPhoto.skin' /* webpackChunkName: "WPhoto_PolaroidPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_PolaroidPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'PolaroidPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/RoundPhoto.skin' /* webpackChunkName: "WPhoto_RoundPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_RoundPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'RoundPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/RoundShadowPhoto.skin' /* webpackChunkName: "WPhoto_RoundShadowPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_RoundShadowPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'RoundShadowPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/ScotchDouble/ScotchDoubleHorizontal.skin' /* webpackChunkName: "WPhoto_ScotchDoubleHorizontal" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchDoubleHorizontal" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ScotchDoubleHorizontal',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/ScotchDouble/ScotchDoubleVertical.skin' /* webpackChunkName: "WPhoto_ScotchDoubleVertical" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchDoubleVertical" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ScotchDoubleVertical',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/BasicWPhoto/ScotchTopPhoto.skin' /* webpackChunkName: "WPhoto_ScotchTopPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchTopPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ScotchTopPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/ScotchTapePhoto/ScotchTapePhoto.skin' /* webpackChunkName: "WPhoto_ScotchTapePhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchTapePhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'ScotchTapePhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/SloppyPhoto/SloppyPhoto.skin' /* webpackChunkName: "WPhoto_SloppyPhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_SloppyPhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'SloppyPhoto',
    );
    hostAPI.registerComponent(
      'WPhoto',
      () => {
        return Promise.all([
          import(
            './viewer/skinComps/VintagePhoto/VintagePhoto.skin' /* webpackChunkName: "WPhoto_VintagePhoto" */
          ),
          import(
            './WPhoto.controller' /* webpackChunkName: "WPhoto_VintagePhoto" */
          ),
        ]).then(([componentModule, controllerModule]) => {
          return {
            component: componentModule.default,
            controller: controllerModule.default,
          };
        });
      },
      'VintagePhoto',
    );
  },
};

export default entry;
